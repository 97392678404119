import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import "./EchangeMessage.styles.scss";
import {
  Badge,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Modal,
  Slide,
  styled,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { InlineIcon } from "@iconify/react";
import ChatMessage, {
  TChatMessageProps,
} from "@/components/ChatMessage/ChatMessage";
import * as moment from "moment";
import "moment/locale/fr";
import { useLayoutEffect } from "react";
import {
  TChatConversation,
  TMemberCurrentExchange,
  TModerationSocket,
  TPaginationResult,
} from "@/features/profile/types";
import {
  selectIsModerationSuspend,
  selectMaxCaracter,
  selectMaxMessage,
  selectMessageClosedModeration,
  selectModerationSocket,
  selectNbreMessageReceive,
  selectNbreMessageSent,
  selectTourStepIsOpen,
} from "@/store/reducers/general_param/general_param.selector";
import {
  apiGetConversation,
  apiSendMessage,
  markMessageAsRead,
} from "@/features/profile/api/exchange.api";
import { Toastify } from "@/utils/toast";
import { store } from "@/store";
import {
  updateMaxMessage,
  updateNbreMessageReceive,
  updateNbreMessageSent,
} from "@/store/reducers/general_param/general_param.actions";
import { AppSocketContext } from "@/routes";
import { socketChannels } from "@/config/socketChannels";
import { updateMessageNotif } from "@/store/reducers/member/member.actions";
import classNames from "classnames";
import useExchangeRole from "@/hooks/useExchangeRole";
import EchangeHeader from "../Echange-header/EchangeHeader";
import { useTour } from "@reactour/tour";
import { axios } from "@/lib/axios";
import EchangeInfos from "../Echange-Infos/EchangeInfos";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.secondary}`,
    "&::after": {
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

interface EchangeMessageProps {
  toogleTopPartInConversation: boolean;
  setToogleTopPartInConversation: React.Dispatch<React.SetStateAction<boolean>>;
  memberCurrentExchange: TMemberCurrentExchange;
  openDiscussion: boolean;
  refreshing: boolean;
}
const EchangeMessage = ({
  toogleTopPartInConversation,
  setToogleTopPartInConversation,
  memberCurrentExchange,
  openDiscussion,
  refreshing,
}: EchangeMessageProps) => {
  const LIMIT_MESSAGE = 50;
  const { sender, receiver } = useExchangeRole();

  const max_message = useSelector(selectMaxMessage);
  const nbre_message_sent = useSelector(selectNbreMessageSent);
  const nbre_message_receive = useSelector(selectNbreMessageReceive);
  const max_caractere = useSelector(selectMaxCaracter);
  const moderation_socket = useSelector(selectModerationSocket);
  const is_moderation_suspend = useSelector(selectIsModerationSuspend);
  const message_closed_moderation = useSelector(selectMessageClosedModeration);

  const [value, setValue] = useState("");
  const [discussion, setDiscussion] = useState<TChatMessageProps[] | []>([]);

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(true);

  const [notifInMessage, setNotifInMessage] = useState(0);

  const [page, setPage] = useState(1);
  const [paginationResult, setPaginationResult] =
    useState<TPaginationResult | null>(null);

  const [toogleHeader, setToogleHeader] = useState(true);
  const handleToogleHeader = () => setToogleHeader(!toogleHeader);

  const [isExpanded, setIsExpanded] = useState(false);
  const toogleIsExpanded = () => setIsExpanded(!isExpanded);

  const theme = useTheme();
  const matches_sm = useMediaQuery(theme.breakpoints.down("sm"));
  const matches_xl = useMediaQuery(theme.breakpoints.down("xl"));

  const scrollRef = useRef<any>();
  const textareaRef = useRef(null);

  const { socketIO } = useContext(AppSocketContext);

  const { setIsOpen } = useTour();
  const tour_step_is_open_from_store = useSelector(selectTourStepIsOpen);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
  };

  const updateIsReadMessage = useCallback(() => {
    const isReadMsgIds = discussion.map((elt) => {
      if (
        elt.isRead === false &&
        elt.pending === false &&
        elt.type === "received"
      ) {
        return elt._id;
      } else {
        return null;
      }
    });

    const idsToSend = isReadMsgIds.filter((elt) => elt !== null);

    console.log("🌍 idsToSend[] ", idsToSend);

    if (false && idsToSend.length > 0) {
      markMessageAsRead(
        memberCurrentExchange.conversationId || "",
        (idsToSend as string[]) || []
      )
        .then(() => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setToogleHeader(toogleTopPartInConversation);
  }, [toogleTopPartInConversation]);

  useEffect(() => {
    if (matches_sm && openDiscussion) {
      setIsOpen(tour_step_is_open_from_store);

      console.log("😅 In Message - matches_sm : ", matches_sm);
      console.log("😅 In Message - openDiscussion : ", openDiscussion);
      console.log(
        "😅 In Message - tour_step_is_open_from_store : ",
        tour_step_is_open_from_store
      );
    }
  }, [matches_sm, openDiscussion, setIsOpen, tour_step_is_open_from_store]);

  useEffect(() => {
    if (textareaRef.current) {
      const textareaElt = textareaRef.current as HTMLTextAreaElement;
      // Reset the height to auto to shrink the textarea if necessary
      textareaElt.style.height = "auto";
      // Set the height to the scroll height to expand the textarea
      textareaElt.style.height = `${textareaElt.scrollHeight}px`;

      // Optional: Limit the height
      const maxHeight = parseInt(
        window.getComputedStyle(textareaElt).maxHeight,
        10
      );
      if (textareaElt.scrollHeight > maxHeight) {
        textareaElt.style.overflowY = "auto";
      } else {
        textareaElt.style.overflowY = "hidden";
      }
    }
  }, [value]);

  const newMessage = () => {
    if (value !== "" && nbre_message_sent < max_message) {
      let tempValue = value;
      setValue("");
      store.dispatch(updateNbreMessageSent(nbre_message_sent + 1));
      setLoading2(true);

      apiSendMessage(
        value,
        memberCurrentExchange?.conversationId ||
          memberCurrentExchange?._id ||
          ""
      )
        .then((response: any) => {
          const chatConversation: TChatConversation = response.data;
          const newMessage: TChatMessageProps = {
            _id: chatConversation._id,
            type: "sent",
            gender: sender?.gender as "MALE" | "FEMALE",
            fromStaff: chatConversation.sendBy === "STAFF",
            pending: chatConversation.status === "PENDING",
            message: tempValue,
            isValid: chatConversation.status === "VALIDATED",
            reason: "",
            note: "",
            isRead: false,
            time:
              moment
                .default(new Date(chatConversation.issueDate))
                .format("LL") +
              " " +
              moment.default(new Date(chatConversation.issueDate)).format("LT"),
          };

          setDiscussion([
            ...discussion,
            {
              ...newMessage,
            },
          ]);

          scrollToBottom();
        })
        .catch((error) => {
          console.log(error);
          error?.response?.data?.message &&
            Toastify(`${error?.response?.data?.message}`, "error", 5000);
          // window.location.reload();
        })
        .finally(() => {
          setValue("");
          setLoading2(false);
          scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        });
    }

    if (nbre_message_sent >= max_message) {
      Toastify(
        `Vous avez dépassé le quota de message journalier`,
        "warning",
        5000
      );
      setValue("");
    }
  };

  const loadConversation = useCallback(
    (page: number) => {
      if (paginationResult?.nextPage === null) {
      } else {
        setLoading(true);
        apiGetConversation(
          page,
          LIMIT_MESSAGE,
          memberCurrentExchange?.conversationId || ""
        )
          .then((response: any) => {
            const data: TChatConversation[] = response.data.result;
            const conversation: TChatMessageProps[] = [...data].map(
              (elt: TChatConversation) => {
                return {
                  _id: elt._id,
                  type: (sender?.memberId === elt.sender.memberId
                    ? "sent"
                    : "received") as "sent" | "received",
                  gender:
                    elt.sendBy === "STAFF"
                      ? "MALE"
                      : (elt.sender.gender.toLowerCase() as "MALE" | "FEMALE"),
                  fromStaff: elt.sendBy === "STAFF",
                  pending: elt.status === "PENDING",
                  message: elt.body,
                  isValid: elt.status === "VALIDATED",
                  reason: elt?.rejectedReason || "",
                  note: elt?.moderationNote || "",
                  isRead: elt.isRead,
                  issueDate: elt.issueDate,
                  time:
                    moment.default(new Date(elt.issueDate)).format("LL") +
                    " " +
                    moment.default(new Date(elt.issueDate)).format("LT"),
                };
              }
            );
            setPaginationResult(response.data.paginationResult);
            setLoading(false);
            setDiscussion((prevData) => [...prevData, ...conversation]);
          })
          .catch((error) => {
            setLoading(false);
            console.log("ERROR MESSAGE", error);
            console.log(error);
            error?.response?.data?.message &&
              Toastify(`${error?.response?.data?.message}`, "error", 5000);
          });
      }
      updateIsReadMessage();
    },
    [
      memberCurrentExchange?.conversationId,
      paginationResult?.nextPage,
      sender?.memberId,
      updateIsReadMessage,
    ]
  );

  const refreshConversation = useCallback(() => {
    setPage(1);
    setPaginationResult(null);

    setLoading(true);
    apiGetConversation(
      1,
      LIMIT_MESSAGE,
      memberCurrentExchange?.conversationId || memberCurrentExchange?._id || ""
    )
      .then((response: any) => {
        const data: TChatConversation[] = response.data.result;
        const conversation: TChatMessageProps[] = [...data].map(
          (elt: TChatConversation) => {
            return {
              _id: elt._id,
              type: (sender?.memberId === elt.sender.memberId
                ? "sent"
                : "received") as "sent" | "received",
              gender:
                elt.sendBy === "STAFF"
                  ? "MALE"
                  : (elt.sender.gender as "MALE" | "FEMALE"),
              fromStaff: elt.sendBy === "STAFF",
              pending: elt.status === "PENDING",
              message: elt.body,
              isValid: elt.status === "VALIDATED",
              reason: elt?.rejectedReason || "",
              note: elt?.moderationNote || "",
              isRead: elt.isRead,
              issueDate: elt.issueDate,
              time:
                moment.default(new Date(elt.issueDate)).format("LL") +
                " " +
                moment.default(new Date(elt.issueDate)).format("LT"),
            };
          }
        );
        setPaginationResult(response.data.paginationResult);
        setLoading(false);
        setDiscussion([...conversation]);

        console.log("🔥");

        store.dispatch(
          updateNbreMessageSent(
            sender?.gender === "MALE"
              ? response.data.todayCount.male
              : response.data.todayCount.female
          )
        );
        store.dispatch(
          updateNbreMessageReceive(
            receiver?.gender === "MALE"
              ? response.data.todayCount.male
              : response.data.todayCount.female
          )
        );
      })
      .catch((error) => {
        setLoading(false);
        console.log("ERROR MESSAGE", error);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      })
      .finally(() => {
        setNotifInMessage(0);
      });
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    updateIsReadMessage();
  }, [
    memberCurrentExchange?._id,
    memberCurrentExchange?.conversationId,
    receiver?.gender,
    sender?.gender,
    sender?.memberId,
    updateIsReadMessage,
  ]);

  useEffect(() => {
    store.dispatch(updateMessageNotif(0)); // <---
    if (memberCurrentExchange !== null) {
      refreshConversation();
      getIsModerationOpen();
      if (scrollRef?.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (refreshing) {
      refreshConversation();
      store.dispatch(updateMessageNotif(0));
    }
  }, [refreshing, refreshConversation]);

  useEffect(() => {
    //console.log("||| ⭐️", socketIO);

    if (socketIO) {
      socketIO.on(socketChannels.member_conversation_messages, (data) => {
        console.log("⭐️ DATA-MESSAGE : ", data);
        setNotifInMessage(1);

        const chatConversation: TChatConversation = data;
        const newMessage: TChatMessageProps = {
          _id: chatConversation._id,
          type: (sender?.memberId === chatConversation.sender.memberId
            ? "sent"
            : "received") as "sent" | "received",
          gender:
            chatConversation.sendBy === "STAFF"
              ? "MALE"
              : (chatConversation.sender.gender.toLowerCase() as
                  | "MALE"
                  | "FEMALE"),
          fromStaff: chatConversation.sendBy === "STAFF",
          pending: chatConversation.status === "PENDING",
          message: chatConversation.body,
          isValid: chatConversation.status === "VALIDATED",
          reason: chatConversation?.rejectedReason || "",
          note: chatConversation?.moderationNote || "",
          isRead: chatConversation.isRead,
          issueDate: chatConversation.issueDate,
          time:
            moment.default(new Date(chatConversation.issueDate)).format("LL") +
            " " +
            moment.default(new Date(chatConversation.issueDate)).format("LT"),
        };

        const findIndex = discussion.findIndex(
          (elt) => elt._id === newMessage._id
        );
        if (findIndex !== -1) {
          console.log("⭐️ modifiication de", newMessage.message);
          setDiscussion(
            discussion.map((elt) =>
              elt._id === newMessage._id ? newMessage : elt
            )
          );
        } else {
          setDiscussion([
            ...discussion,
            {
              ...newMessage,
            },
          ]);
        }
        scrollToBottom();
      });
      socketIO.on(socketChannels.nbre_message_available_count, (data) => {
        console.log(data);

        store.dispatch(updateNbreMessageSent(data.sender));
        store.dispatch(updateNbreMessageReceive(data.receiver));
      });
      socketIO.on(socketChannels.is_moderation_open, (data) => {
        // console.log("🌍", data);
        // store.dispatch(updateIsModerationSuspend(data.isOpen));
        setIsModerationOpen(data.isOpen);
      });
      socketIO.on(socketChannels.messages_mark_as_read, (data) => {
        setDiscussion(
          discussion.map((elt) =>
            data.includes(elt._id) ? { ...elt, isRead: true } : elt
          )
        );
      });
    } else {
      console.log("⭐️ CLOSED : ", socketIO);
    }

    return () => {
      if (socketIO) {
        // socketIO.off(socketChannels.member_conversation_messages);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketIO, discussion]);

  const scrollToBottom = useCallback(() => {
    if (scrollRef?.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [scrollRef]);

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  const handleScroll = useCallback(() => {
    if (scrollRef.current.scrollTop === 0 && !loading) {
      if (paginationResult?.nextPage === null) {
      } else {
        const nextPage = page + 1;
        setPage(nextPage);
        loadConversation(nextPage);
      }
    }
  }, [loadConversation, loading, page, paginationResult?.nextPage]);

  useEffect(() => {
    const container = scrollRef.current;

    container && container.addEventListener("scroll", handleScroll);

    return () => {
      container && container.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, loading, page]);

  const [isModerationOpen, setIsModerationOpen] = useState(false);

  const getIsModerationOpen = useCallback(() => {
    axios
      .get("/param-s/api/v1/parameters/app-plannings/is-open")
      .then((response: any) => {
        // store.dispatch(updateIsModerationSuspend(response.data.isOpen));
        setIsModerationOpen(response.data);
      })
      .catch((error) => {});
  }, []);

  const isModerationReallyAvailable = useCallback((): boolean => {
    //console.log(isModerationOpen);
    //console.log(is_moderation_suspend);
    if (is_moderation_suspend === true) {
      return false;
    } else {
      if (isModerationOpen === true) {
        return true;
      } else {
        return false;
      }
    }
  }, [isModerationOpen, is_moderation_suspend]);

  useEffect(() => {
    setLoading3(true);
    setTimeout(() => {
      setLoading3(false);
    }, 2000);
  }, []);

  useEffect(() => {
    updateIsReadMessage();
  }, [updateIsReadMessage]);

  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <>
      <div className={`c-echange-message ${isExpanded ? "expanded" : ""} `}>
        <Collapse
          orientation="vertical"
          in={matches_xl ? toogleTopPartInConversation : true}
          mountOnEnter
          unmountOnExit
        >
          <div className="top-part">
            <div className="top-part-toolbar">
              <Button
                className="refresh-conversation-btn"
                size="medium"
                variant="contained"
                color="warning"
                onClick={handleOpenModal}
                startIcon={<InlineIcon icon="mi:options-vertical" />}
              >
                <span className="refresh-conversation-text">
                  Options de conversation
                </span>
              </Button>
              <CustomTooltip title="Actualiser la conversation">
                <IconButton
                  style={{ background: "var(--ui-primary)" }}
                  color="secondary"
                  size="small"
                  onClick={() => {
                    refreshConversation();
                    store.dispatch(updateMessageNotif(0));
                  }}
                >
                  {loading ? (
                    <CircularProgress color="secondary" size={10} />
                  ) : (
                    <InlineIcon icon="material-symbols:sync-rounded" />
                  )}
                  <StyledBadge
                    overlap="circular"
                    variant="dot"
                    color="warning"
                    badgeContent={notifInMessage}
                  />
                </IconButton>
              </CustomTooltip>
              <CustomTooltip title={isExpanded ? "Rétrécir" : "Agrandir"}>
                <IconButton
                  className="toogle-expand-shrink"
                  color="primary"
                  onClick={toogleIsExpanded}
                >
                  <InlineIcon
                    icon={
                      isExpanded
                        ? "streamline:interface-arrows-shrink-5-expand-retract-shrink-bigger-big-small-smaller"
                        : "fluent:expand-up-right-28-filled"
                    }
                  />
                </IconButton>
              </CustomTooltip>
            </div>
            <CustomTooltip
              title={
                toogleHeader
                  ? "Masquer le détail des membres"
                  : "Afficher  le détail des membres"
              }
            >
              <IconButton className="toogle-btn" onClick={handleToogleHeader}>
                <InlineIcon
                  icon={
                    toogleHeader
                      ? "heroicons-outline:chevron-up"
                      : "heroicons-outline:chevron-down"
                  }
                  fontSize={18}
                  height={20}
                />
              </IconButton>
            </CustomTooltip>

            <p>
              Tous les messages envoyés sont intégralement lus, contrôlés et
              validés par le service de modération avant d'être transmis à
              &nbsp;
              <b>{receiver?.username || ""}</b>
            </p>
          </div>
        </Collapse>
        <Collapse
          orientation="vertical"
          in={
            matches_sm
              ? toogleTopPartInConversation && toogleHeader
              : toogleHeader
          }
          mountOnEnter
          unmountOnExit
        >
          <div className="header-exchange">
            <EchangeHeader memberCurrentExchange={memberCurrentExchange} />
          </div>
        </Collapse>
        <div
          className={classNames("content-part", {
            unavailable: !(
              (moderation_socket as TModerationSocket).status &&
              !is_moderation_suspend
            ),
          })}
          ref={scrollRef}
        >
          <>
            {!toogleTopPartInConversation && (
              <CustomTooltip title={isExpanded ? "Rétrécir" : "Agrandir"}>
                <IconButton
                  className={`expand-btn-absolute ${isExpanded && "expanded"}`}
                  size="small"
                  onClick={() => {
                    toogleIsExpanded();
                    scrollToBottom();
                    setToogleTopPartInConversation(true);
                  }}
                >
                  <InlineIcon
                    icon={
                      isExpanded
                        ? "streamline:interface-arrows-shrink-5-expand-retract-shrink-bigger-big-small-smaller"
                        : "fluent:expand-up-right-28-filled"
                    }
                  />
                </IconButton>
              </CustomTooltip>
            )}
          </>

          <>
            {loading && (
              <img
                src={require("@/assets/images/gif/loadmore.gif")}
                height="70"
                width="70"
                className="load-more"
                alt="load-more"
              />
            )}
            {discussion.length === 0 && (
              <div className="no-text">
                <p> Commencez à discuter avec votre partenaire </p>
              </div>
            )}
            {discussion.length > 0 && (
              <>
                {discussion
                  .sort(
                    (a, b) =>
                      new Date(a?.issueDate || "").getTime() -
                      new Date(b?.issueDate || "").getTime()
                  )
                  .map((msg, index) => {
                    return (
                      <ChatMessage
                        key={index}
                        _id={msg._id}
                        message={msg.message}
                        reason={msg.reason}
                        fromStaff={msg.fromStaff}
                        pending={msg.pending}
                        isValid={msg.isValid}
                        type={msg.type}
                        gender={msg.gender}
                        time={msg.time}
                        note={msg.note}
                        isRead={msg.isRead}
                      />
                    );
                  })}
                {loading2 && (
                  <img
                    src={require("@/assets/images/gif/loadmore.gif")}
                    height="70"
                    width="70"
                    className="load-more"
                    alt="load-more"
                  />
                )}
              </>
            )}
          </>
        </div>
        <div
          className={classNames("bottom-part", {
            unavailable: !isModerationReallyAvailable(),
          })}
        >
          <>
            {loading3 ? (
              <>
                <CircularProgress color="primary" size={20} />
              </>
            ) : (
              <>
                {isModerationReallyAvailable() ? (
                  <>
                    <IconButton
                      className="sroll-to-bottom"
                      size="small"
                      onClick={scrollToBottom}
                    >
                      <InlineIcon icon="ep:arrow-down" />
                    </IconButton>
                    <div className="sender-input">
                      <textarea
                        ref={textareaRef}
                        className="send-message-input"
                        maxLength={max_caractere}
                        value={value}
                        onChange={handleChange}
                        onBlur={() => {
                          updateIsReadMessage();
                        }}
                      ></textarea>
                      <IconButton className="send-btn" onClick={newMessage}>
                        <InlineIcon icon="fluent:send-28-regular" />
                      </IconButton>
                    </div>
                    <small>
                      {" "}
                      {value.length} / {max_caractere} caractères
                    </small>
                  </>
                ) : (
                  <>
                    <span className="title-unavailable">
                      {" "}
                      SERVICE INDISPONIBLE{" "}
                    </span>
                    <p className="text-unavailable">
                      {" "}
                      {message_closed_moderation}{" "}
                    </p>
                  </>
                )}
              </>
            )}
          </>
        </div>
      </div>

      {/* MODALS PART */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Slide direction="left" in={openModal} mountOnEnter unmountOnExit>
          <div className="modal-detail_exchange-container">
            <div className="modal-detail_exchange-container__title-part">
              <IconButton
                className="back-icon"
                size="medium"
                color="secondary"
                onClick={handleCloseModal}
              >
                <InlineIcon icon="tabler:chevron-left" />
              </IconButton>
              <div className="title-infos-username-age">
                <div className="username-age">
                  <p className="username">Options de conversation</p>
                </div>
              </div>
              <IconButton
                className="close-icon"
                size="medium"
                color="secondary"
                onClick={handleCloseModal}
              >
                <InlineIcon icon="iconamoon:close-bold" />
              </IconButton>
            </div>
            <div className="modal-detail_exchange-container__body-part">
              <>
                <EchangeInfos
                  isDisplayedInModal={true}
                  isDiscussing={true}
                  memberCurrentExchange={memberCurrentExchange}
                />
              </>
            </div>
          </div>
        </Slide>
      </Modal>
    </>
  );
};

export default EchangeMessage;
