import { InlineIcon } from "@iconify/react";
import {
  Box,
  Button,
  Grid,
  Modal,
  TextField,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Chip,
  Slide,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "moment/locale/fr";
import "./ProfileCard.styles.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { HighlightOffRounded } from "@mui/icons-material";
import { TCurrentExchange, TMemberCurrentExchange } from "../../types";
import HistoriqueConversation from "./Modals-content/Historique-conversation/HistoriqueConversation";
import { Toastify } from "@/utils/toast";
import { store } from "@/store";
import { apiAcceptDemand, apiRemindDemand } from "../../api/exchange.api";
import {
  updateCurrentExchange,
  updateOpenDiscussion,
} from "@/store/reducers/general_param/general_param.actions";
import { FavoriteContext } from "../../pages/Favorite-content/Favorite.content";
import { BlacklistContext } from "../../pages/Blacklist-content/Blacklist.content";
import { ExchangeContext } from "../../pages/Exchange-content/Exchange.content";
import {
  updateAcceptExchange,
  updateMemberCurrentExchange,
} from "@/store/reducers/member/member.actions";
import TextDisplayer from "@/components/TextDisplayer/TextDisplayer";
import { TMemberProfile } from "@/features/auth/types";
import { selectMemberProfile } from "@/store/reducers/member/member.selector";
import { useSelector } from "react-redux";
import ModalFullProfil from "@/components/Modal-full-profile/ModalFullProfil";
import * as moment from "moment";
import "moment/locale/fr";

export type TProfileCard = {
  _id?: string;
  conversationId?: string;
  alt?: boolean;
  no_corner_option?: boolean;
  isVerified?: boolean;
  isOnline?: boolean;
  hasMore?: boolean;
  username?: string;
  type?: "consulted" | "non consultés" | "Mes spectateurs" | "";
  progressValue?: number | undefined;
  profil_id: string;
  age?: number;
  town?: string | null;
  country?: string | null;
  origine?: string | null;
  nationalite?: string | null;
  sendStatut?: "sent" | "received" | "";
  description?: string;
  profile_wanted?: string;
  isCloture?: boolean;
  isExchange?: boolean;
  isMutualFavorite?: boolean;
  isSimpleFavorite?: boolean;
  cloture?: { date_cloture: string; motif: string; emitter: string } | null;
  isListeNoire?: boolean;
  conversation_id?: string;
  has_conversation?: boolean;
  date_of_creation?: string;
  is_deleted?: boolean;
  last_connection?: string | null;
  closure?: {
    closedBy: string;
    closedAt: string;
    reason: string | null;
  };
  currentConversation?: TMemberCurrentExchange | null;
  isVisited?: boolean;
  isVisitor?: boolean;
  gender?: "MALE" | "FEMALE";
  refreshHome?: (
    type?:
      | "add-favorite"
      | "remove-favorite"
      | "add-blacklist"
      | "remove-blacklist"
      | "visited"
  ) => void;
  fromFan?: boolean;
  is_banished?: boolean;
};

const ProfileCard = ({
  _id,
  conversationId,
  alt = false,
  no_corner_option = false,
  isVerified,
  isOnline,
  username,
  type,
  progressValue,
  profil_id,
  age,
  town,
  country,
  origine,
  nationalite,
  hasMore,
  sendStatut,
  description,
  profile_wanted,
  isCloture = false,
  isExchange = false,
  isMutualFavorite = false,
  isSimpleFavorite = false,
  cloture = null,
  isListeNoire = false,
  conversation_id = "",
  has_conversation = false,
  date_of_creation = "",
  is_deleted = false,
  last_connection = null,
  closure,
  currentConversation,
  isVisited,
  isVisitor,
  gender,
  refreshHome,
  fromFan,
  is_banished = false,
}: TProfileCard) => {
  const memberProfileFromStore = useSelector(
    selectMemberProfile
  ) as TMemberProfile;

  const [openFullProfil, setOpenFullProfil] = useState(false);

  const [openDenyRequest, setOpenDenyRequest] = useState(false);
  const [openConfirmedDenyRequest, setOpenConfirmedDenyRequest] =
    useState(false);
  const [openRemoveFavorite, setOpenRemoveFavorite] = useState(false);
  const [openRemoveBlacklist, setOpenRemoveBlacklist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const [loadingRemind, setLoadingRemind] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenFullProfil = () => setOpenFullProfil(true);
  const handleCloseFullProfil = () => setOpenFullProfil(false);

  const handleOpenDenyRequest = () => setOpenDenyRequest(true);
  const handleCloseDenyRequest = () => {
    formikDenyRequest.resetForm();
    setOpenDenyRequest(false);
  };

  const handleOpenConfirmedDenyRequest = () =>
    setOpenConfirmedDenyRequest(true);
  const handleCloseConfirmedDenyRequest = () =>
    setOpenConfirmedDenyRequest(false);

  const handleOpenRemoveFavorite = () => setOpenRemoveFavorite(true);
  const handleCloseRemoveFavorite = () => setOpenRemoveFavorite(false);

  const handleOpenRemoveBlacklist = () => setOpenRemoveBlacklist(true);
  const handleCloseRemoveBlacklist = () => setOpenRemoveBlacklist(false);

  const validationSchema = yup.object({
    motif: yup.string().required(" la raison est obligatoire "),
  });

  const formikDenyRequest = useFormik({
    initialValues: {
      motif: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        handleOpenConfirmedDenyRequest();
      }, 500);
    },
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { removeFromBlacklistFct, loadingRemoveB } =
    useContext(BlacklistContext);

  const { removeFromFavoriteFct, loadingRemove } = useContext(FavoriteContext);

  const {
    acceptExchangeFct,
    loadingAccept,
    rejectDemandFct,
    loadingReject,
    cancelDemandFct,
    loadingCancel,
  } = useContext(ExchangeContext);

  const remindExchange = () => {
    setLoadingRemind(true);

    apiRemindDemand(_id || "")
      .then((response: any) => {
        setLoadingRemind(false);
        console.log(response);
        Toastify(
          ` La relance a bien été effectuée auprès de ${username} `,
          "success",
          5000
        );
      })
      .catch((error) => {
        setLoadingRemind(false);
        console.log(error);
        error?.response?.data?.message &&
          Toastify(`${error?.response?.data?.message}`, "error", 5000);
      });
  };

  return (
    <>
      <div
        className={`card-profile `}
        // onClick={handleOpenFullProfil}
      >
        {false && (
          <div className="visited-indicator">
            {isVisited && <div className="visited" />}
            {isVisitor && <div className={`visitor ${gender} `} />}
          </div>
        )}
        <div className="visited-indicator-text">
          {isVisited && (
            <span className="visited"> J'ai consulté son profil </span>
          )}
          {isVisitor && (
            <span className={`visitor ${gender} `}>
              {" "}
              {gender === "MALE" ? "Il a " : "Elle a"} consulté mon profil{" "}
            </span>
          )}
        </div>
        <>
          <div className={`card-profile__header`}>
            <div className="pastille-part">
              <div className={`pastille ${isVerified ? "" : "ko"} `}>
                <InlineIcon
                  icon={isVerified ? "humbleicons:verified" : "ep:close-bold"}
                />
              </div>
              <p className="profil-id">ID: {profil_id} </p>
            </div>
            <div className="tag-info-part">
              <div className="tag">
                <InlineIcon icon="el:globe" />
                {origine ? (
                  <p>
                    <span>Origine</span> <b title={origine}>{origine}</b>
                  </p>
                ) : (
                  <p> -- </p>
                )}
              </div>
              <div className="tag">
                <InlineIcon icon="icon-park-outline:passport" />
                {nationalite ? (
                  <p>
                    <span>Nationalité</span>{" "}
                    <b title={nationalite}>{nationalite}</b>{" "}
                  </p>
                ) : (
                  <p> -- </p>
                )}
              </div>
              <div className="tag">
                <InlineIcon icon="akar-icons:location" />
                <p title={town + "•" + country}>
                  {town ? town : "--"} , {country ? country : "--"}
                </p>
              </div>
            </div>
          </div>
        </>

        <>
          <div className="card-profile__middle">
            <div className="profil-infos-username-age">
              {isMutualFavorite && (
                <InlineIcon
                  icon="iconamoon:star-fill"
                  style={{ color: "orange", marginRight: "10px" }}
                />
              )}
              {isSimpleFavorite && !isMutualFavorite && (
                <InlineIcon
                  icon="iconamoon:star-duotone"
                  style={{ color: "orange", marginRight: "10px" }}
                />
              )}

              <div className="username-age">
                <p className="username" title={username}>
                  {username}
                </p>
                <p className="age">
                  | {age}
                  <small>ans</small>{" "}
                </p>
              </div>

              <div className={`dot  ${isOnline ? "" : "offline"}`}></div>
            </div>
            <p
              className={`profil-progress-text ${progressValue! <= 30 ? "bad" : progressValue! <= 70 ? "middle" : ""}`}
            >
              Profil rempli à <b>{progressValue}%</b>{" "}
            </p>
          </div>
        </>

        <>
          {closure === undefined && (
            <div className="card-profile__detail-part">
              <TextDisplayer
                className="detail-text-part"
                rows={1}
                value={description || ""}
                maxLength={10}
              />
              {currentConversation !== null && (
                <p
                  className="detail-text-part"
                  style={{ color: "var(--ui-red)", marginTop: "10px" }}
                >
                  {" "}
                  <InlineIcon icon="fluent-emoji-high-contrast:handshake" />{" "}
                  &nbsp;&nbsp;
                  <b>Cet utilisateur est déjà en échange</b>{" "}
                </p>
              )}
              {is_deleted && (
                <p
                  className="detail-text-part"
                  style={{ color: "var(--ui-red)", marginTop: "10px" }}
                >
                  {" "}
                  <InlineIcon icon="streamline:interface-user-delete-actions-close-delete-deny-fail-geometric-human-person-remove-single-up-user" />{" "}
                  &nbsp;&nbsp;<b>Cet utilisateur a supprimé son compte</b>{" "}
                </p>
              )}
              {is_banished && (
                <p
                  className="detail-text-part"
                  style={{ color: "var(--ui-red)", marginTop: "10px" }}
                >
                  {" "}
                  <InlineIcon icon="tdesign:user-blocked" /> &nbsp;&nbsp;
                  <b>Cet utilisateur est banni.</b>{" "}
                </p>
              )}
              {sendStatut === "sent" && !isCloture && (
                <p
                  className="detail-text-part"
                  style={{ color: "var(--ui-blue)", marginTop: "10px" }}
                >
                  {" "}
                  <InlineIcon icon="carbon:mail-reply" />
                  &nbsp;&nbsp;<b>Demande envoyée</b>{" "}
                </p>
              )}
              {sendStatut === "received" && !isCloture && (
                <p
                  className="detail-text-part"
                  style={{ color: "var(--ui-red)", marginTop: "10px" }}
                >
                  <InlineIcon icon="carbon:mail-reply" flip="horizontal" />
                  &nbsp;&nbsp;<b>Demande reçue</b>{" "}
                </p>
              )}
            </div>
          )}
        </>

        <>
          {closure !== undefined && (
            <div className="card-profile__cloture-part">
              <p className="cloture-text">
                {" "}
                Échange clôturé par &nbsp;<b>{closure?.closedBy || "yel"}</b>
                &nbsp; pour le motif : <br />
                <b>{closure?.reason || "***"}</b>&nbsp;{" "}
              </p>
              <small className="cloture-text-small">
                {" "}
                Échange fermé le 
                <b>
                  {" "}
                  {moment.default(closure?.closedAt).format("LLL") ||
                    "01/12/2024"}{" "}
                </b>{" "}
              </small>
            </div>
          )}
        </>

        <div className="card-profile__bottom-part">
          {sendStatut === "received" && closure === undefined && (
            <>
              <Chip
                color="error"
                variant="outlined"
                size="medium"
                label="Refuser"
                icon={
                  <InlineIcon
                    icon={
                      loadingReject
                        ? "eos-icons:three-dots-loading"
                        : "mingcute:close-fill"
                    }
                  />
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  !loadingReject && handleOpenDenyRequest();
                }}
              />
              <Chip
                color="success"
                variant="filled"
                size="medium"
                label="Accepter"
                disabled={
                  !!memberProfileFromStore?.currentConversation ||
                  currentConversation !== null
                }
                icon={
                  <InlineIcon
                    icon={
                      loadingAccept
                        ? "eos-icons:three-dots-loading"
                        : "mingcute:check-fill"
                    }
                  />
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  !loadingAccept &&
                    acceptExchangeFct(conversationId || "", username!);
                }}
              />
            </>
          )}
          {sendStatut === "sent" && closure === undefined && (
            <>
              <Chip
                color="error"
                variant="outlined"
                size="medium"
                label="Annuler"
                icon={
                  <InlineIcon
                    icon={
                      loadingCancel
                        ? "eos-icons:three-dots-loading"
                        : "mingcute:close-fill"
                    }
                  />
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  !loadingCancel &&
                    cancelDemandFct(conversationId || "", username!);
                }}
              />
              <Chip
                color="info"
                variant="filled"
                size="medium"
                label="Relancer"
                disabled={
                  !!memberProfileFromStore?.currentConversation ||
                  currentConversation !== null
                }
                icon={
                  <InlineIcon
                    icon={
                      loadingRemind
                        ? "eos-icons:three-dots-loading"
                        : "mingcute:check-fill"
                    }
                  />
                }
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  !loadingRemind && remindExchange();
                }}
              />
            </>
          )}

          {closure === undefined && !isListeNoire && (
            <Chip
              color="primary"
              variant="outlined"
              size="medium"
              label="Voir le profil"
              icon={<InlineIcon icon="iconamoon:profile-fill" />}
              onClick={handleOpenFullProfil}
            />
          )}
          {closure !== undefined && (
            <Chip
              color="primary"
              variant="filled"
              size="medium"
              label="Historique des conversations"
              icon={<InlineIcon icon="ic:round-history" />}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleOpenModal();
              }}
            />
          )}

          {alt && (
            <>
              {isListeNoire ? (
                <Chip
                  color="primary"
                  variant="outlined"
                  size="medium"
                  label="Retirer de la liste noire"
                  icon={<InlineIcon icon="tabler:user-x" />}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOpenRemoveBlacklist();
                  }}
                />
              ) : (
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOpenRemoveFavorite();
                  }}
                >
                  <InlineIcon icon="mingcute:delete-fill" />
                </IconButton>
              )}
            </>
          )}
        </div>
      </div>

      {/* FULL PROFIL */}
      {openFullProfil && (
        <ModalFullProfil
          _id={_id}
          openModal={openFullProfil}
          closeModal={handleCloseFullProfil}
          isOnline={isOnline}
          isVerified={isVerified}
          hasMore={hasMore}
          username={username}
          type={type}
          age={age}
          town={town}
          country={country}
          origine={origine}
          profil_id={profil_id}
          nationalite={nationalite}
          progressValue={progressValue}
          date_of_creation={date_of_creation}
          last_connection={last_connection}
          sendStatut={sendStatut}
          isSimpleFavorite={isSimpleFavorite}
          isMutualFavorite={isMutualFavorite}
          isListeNoire={isListeNoire}
          refreshHome={refreshHome}
          fromFan={fromFan}
        />
      )}
      {/* End of FULL PROFIL */}

      {/* DENY REQUEST */}
      {openDenyRequest && (
        <Modal
          open={openDenyRequest}
          onClose={handleCloseDenyRequest}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form
            className="form-raison-part"
            onSubmit={formikDenyRequest.handleSubmit}
          >
            <IconButton
              className="close-icon"
              size="large"
              color="inherit"
              onClick={handleCloseDenyRequest}
            >
              <HighlightOffRounded />
            </IconButton>
            <TextField
              placeholder="La raison du refus"
              variant="outlined"
              label="Raison"
              name="motif"
              onChange={formikDenyRequest.handleChange}
              value={formikDenyRequest.values.motif}
              error={
                formikDenyRequest.touched.motif &&
                Boolean(formikDenyRequest.errors.motif)
              }
              helperText={
                formikDenyRequest.touched.motif &&
                (formikDenyRequest.errors.motif as string)
              }
              type="text"
            />

            <Box sx={{ width: "100%" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6}>
                  <Button
                    variant="contained"
                    color="tertiary"
                    onClick={handleCloseDenyRequest}
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                  >
                    {" "}
                    ANNULER{" "}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} lg={6}>
                  <LoadingButton
                    loading={loading}
                    loadingPosition="center"
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: "30px", width: "100%" }}
                    type="submit"
                    disabled={!formikDenyRequest.isValid}
                  >
                    {" "}
                    REFUSER{" "}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Modal>
      )}

      {/* End of DENY REQUEST */}

      {/* DENY REQUEST  DIALOG PART */}
      {openConfirmedDenyRequest && (
        <Dialog
          open={openConfirmedDenyRequest}
          onClose={handleCloseConfirmedDenyRequest}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Refus de demande"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <b>Attention!!!</b> cette action est irréversible.
              <br />
              Vous êtes sur le point de <b>refuser</b> la demande d'échange de
              ce profil. Êtes-vous sûr(e) de vouloir le faire ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmedDenyRequest}>Annuler</Button>
            <LoadingButton
              id="id-signal-btn"
              loading={loading2}
              loadingPosition="center"
              onClick={() => {
                rejectDemandFct(
                  conversationId || "",
                  username!,
                  formikDenyRequest.values.motif
                );
                handleCloseDenyRequest();
                handleCloseConfirmedDenyRequest();
              }}
              autoFocus
            >
              Refuser
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {/* Ennd of DENY REQUEST  DIALOG PART */}

      {/* RETIRER FAVORIS  DIALOG PART */}
      {openRemoveFavorite && (
        <Dialog
          open={openRemoveFavorite}
          onClose={handleCloseRemoveFavorite}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Retirer des favoris"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* <b>Attention!!!</b> cette action est déliquate.<br /> */}
              Vous êtes sur le point de <b> retirer </b> ce profil de vos
              favoris. Êtes-vous sûr(e) de vouloir le faire ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRemoveFavorite}>Annuler</Button>
            <LoadingButton
              loading={loadingRemove}
              loadingPosition="center"
              variant="contained"
              color="primary"
              id="id-remove-favorite-btn"
              onClick={() => {
                removeFromFavoriteFct(_id || "", username!);
                handleCloseRemoveFavorite();
              }}
              type="submit"
            >
              {" "}
              Retirer{" "}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {/* Ennd of RETIRER FAVORIS  DIALOG PART */}

      {/* RETIRER LISTE NOIRE  DIALOG PART */}
      {openRemoveBlacklist && (
        <Dialog
          open={openRemoveBlacklist}
          onClose={handleCloseRemoveBlacklist}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Retirer de la liste noire"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* <b>Attention!!!</b> cette action est déliquate.<br /> */}
              Vous êtes sur le point de <b> retirer </b> ce profil de votre
              liste noire. Êtes-vous sûr(e) de vouloir le faire ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseRemoveBlacklist}>Annuler</Button>
            <LoadingButton
              loading={loadingRemoveB}
              loadingPosition="center"
              variant="contained"
              color="primary"
              id="id-remove-blacklist-btn"
              onClick={() => {
                removeFromBlacklistFct(_id || "", username!);
                handleCloseRemoveBlacklist();
              }}
              type="submit"
            >
              {" "}
              Retirer{" "}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      )}
      {/* Ennd of RETIRER LISTE NOIRE  DIALOG PART */}

      {/* HISTORIQUE CONVERSATION */}
      {openModal && (
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Slide direction="left" in={openModal} mountOnEnter unmountOnExit>
            <div className="modal-historique-container">
              <div className="modal-historique-container__title-part">
                <IconButton
                  className="back-icon"
                  size="medium"
                  color="secondary"
                  onClick={handleCloseModal}
                >
                  <InlineIcon icon="tabler:chevron-left" />
                </IconButton>
                <div className="title-infos-username-age">
                  <div className="username-age">
                    <p className="username">Historique de conversation</p>
                  </div>
                </div>
                <IconButton
                  className="close-icon"
                  size="medium"
                  color="secondary"
                  onClick={handleCloseModal}
                >
                  <InlineIcon icon="iconamoon:close-bold" />
                </IconButton>
              </div>
              <div className="modal-historique-container__body-part">
                <HistoriqueConversation
                  closeModal={handleCloseModal}
                  conversation_id={conversationId || ""}
                />
              </div>
            </div>
          </Slide>
        </Modal>
      )}
      {/* End of HISTORIQUE CONVERSATION */}
    </>
  );
};

export default ProfileCard;
